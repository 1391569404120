import { useContext } from "react";

import moment from "moment";

import { Button, Divider, Icon } from "components/common/basic";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  email: string;
}

export default ({ email }: Props): JSX.Element => {
  const { closeAllPopups } = useContext(PopupContext);
  const { t } = useTranslation("account");

  return (
    <div className={tw("flex", "flex-col", "space-y-6", "items-center")}>
      <div
        className={tw(
          "max-w-2xl",
          "p-8",
          "space-y-8",
          "rounded-lg",
          "border",
          "border-deepBlue-50"
        )}
      >
        <Icon
          icon="HiOutlineCheckCircle"
          color="text-success"
          size={56}
          strokeWidth={3}
        />

        <div className={tw("space-y-4")}>
          <h1 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
            {t("popup.updateSubscription_success.heading", "Congratulations!")}
          </h1>

          <p
            className={tw(
              "text-deepBlue-900",
              "text-2xl",
              "whitespace-pre-wrap"
            )}
          >
            {t(
              "popup.updateSubscription_success.body",
              "Thank you for upgrading your subscription plan."
            )}
          </p>
        </div>

        <div className={tw("space-y-4")}>
          <Divider />

          <div className={tw("flex", "justify-between")}>
            <p className={tw("text-lg", "text-deepBlue-700")}>
              {t("popup.updateSubscription_success.orderDate", "Order date:")}
            </p>

            <p className={tw("text-lg", "text-deepBlue-900", "font-bold")}>
              {moment().format("DD MMM. YYYY")}
            </p>
          </div>

          <Divider />
        </div>

        <p className={tw("text-lg", "text-deepBlue-700")}>
          <Trans
            ns="account"
            i18nKey="popup.updateSubscription_success.emailText"
            defaults="We have sent detailed information about the order confirmation to: <0>{{ email }}</0>"
            values={{ email }}
            components={[
              <strong className={tw("text-deepBlue-900", "font-bold")} />,
            ]}
          />
        </p>
      </div>

      <Button
        id="subscription_change_popup-close_popup"
        onClick={closeAllPopups}
      >
        {t("popup.updateSubscription_success.button.close", "Close")}
      </Button>
    </div>
  );
};
