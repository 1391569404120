import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as Yup from "yup";

import {
  Button,
  CheckBox,
  Divider,
  Icon,
  Input,
  RadioPill,
} from "components/common/basic";
import { AccountFragment, PaymentMethodFragment } from "graphql/fragments";
import { useCreateStripeCardMutation } from "graphql/mutations";
import { EntityTypeEnum } from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { gTag } from "utils";
import { tw } from "utils/tw";

import poweredByStripe from "assets/credit_cards/powered_by_stripe.svg";

import { StripeContext } from "./StripeWrapper";

interface FormValues {
  invoiceEmail?: string;
}

interface Props {
  account: AccountFragment;
  isFlat: boolean;
  hasPaymentMethods: boolean;
  submitLabel?: string;
  stripeReturnUrl?: URL;
  onSuccess?: (paymentMethod: PaymentMethodFragment) => void;
}

export default ({
  account,
  isFlat,
  hasPaymentMethods,
  submitLabel,
  stripeReturnUrl = new URL(window.location.toString()),
  onSuccess,
}: Props): JSX.Element => {
  const [entityType, setEntityType] = useState(EntityTypeEnum.Organization);
  const [isDefault, setIsDefault] = useState(!hasPaymentMethods);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const { createStripeCard } = useCreateStripeCardMutation();

  const { t } = useTranslation("common");
  const { clientSecret } = useContext(StripeContext);
  const { closeOnePopup } = useContext(PopupContext);
  const { session } = useContext(AuthContext);
  const [_searchParams, setSearchParams] = useSearchParams();

  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = Yup.object<FormValues>().shape({
    invoiceEmail: Yup.string().email(
      t("checkoutForm_creditCard.email.error.format", "Incorrect email format")
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onStripeSubmit = ({ invoiceEmail }: FormValues) => {
    if (!stripe || !elements) return;

    setIsLoading(true);

    const urlBase = stripeReturnUrl.origin + stripeReturnUrl.pathname;
    const urlSearchParams = new URLSearchParams([
      ...Array.from(stripeReturnUrl.searchParams.entries()),
      ["entity_type", entityType],
      ...(isDefault ? [["set_default", "true"]] : []),
      ...(invoiceEmail ? [["invoice_email", invoiceEmail]] : []),
    ]);
    const confirmParams = {
      return_url: `${urlBase}?${urlSearchParams.toString()}`,
    };

    stripe
      .confirmSetup({
        elements,
        confirmParams,
        clientSecret,
        redirect: "if_required",
      })
      .then(({ setupIntent, error }) => {
        // SetupIntent is only available if a redirect is not required
        if (setupIntent?.status === "succeeded") {
          const attributes = {
            stripeSetupIntentId: setupIntent.id,
            email: invoiceEmail,
            entityType,
            default: isDefault,
          };

          createStripeCard(attributes, (paymentMethod) => {
            setSearchParams(
              new URLSearchParams([["payment_method_setup", "succeeded"]])
            );

            if (session?.account.registrationTier)
              gTag("conversion_event_subscribe_paid", {
                item_id: session.account.registrationTier.id,
                item_name: session.account.registrationTier.type,
                currency: session.account.registrationTier.currency,
                value: session.account.registrationTier.amount,
              });

            if (onSuccess) onSuccess(paymentMethod);
            else closeOnePopup();
          });
        } else {
          switch (error?.type) {
            case "validation_error":
              break;

            case "card_error":
              setErrorMessage(error.message ?? "");
              break;

            default:
              setErrorMessage(
                t(
                  "checkoutForm_creditCard.stripeError.unknown",
                  "An unexpected error occurred."
                )
              );
          }
        }

        setIsLoading(false);
      });
  };

  const cardStyles = tw("w-full", "bg-white", "space-y-6", {
    [tw("py-6", "px-4", "shadow-md", "rounded-lg")]: !isFlat,
  });

  return (
    <form
      onSubmit={(event) => {
        // Trigger validation
        elements?.submit();

        handleSubmit(onStripeSubmit)(event);
      }}
      className={tw("space-y-6")}
    >
      <div className={cardStyles}>
        <div className={tw("flex", "justify-between")}>
          <div className={tw("flex", "items-center", "space-x-2")}>
            <Icon icon="HiCreditCard" isFilled />

            <h2>
              {t("checkoutForm_creditCard.heading", "Credit card information")}
            </h2>
          </div>

          <img src={poweredByStripe} alt="Powered by Stripe" />
        </div>

        <PaymentElement />

        <Divider />

        <div className={tw("space-x-3")}>
          {[
            {
              label: t(
                "checkoutForm_creditCard.addressType.organization",
                "Organisation"
              ),
              value: EntityTypeEnum.Organization,
            },
            {
              label: t(
                "checkoutForm_creditCard.addressType.individual",
                "Individual"
              ),
              value: EntityTypeEnum.Individual,
            },
          ].map((option) => (
            <RadioPill
              key={option.value}
              id={option.value}
              label={option.label}
              value={option.value}
              checked={entityType === option.value}
              onChange={() => setEntityType(option.value)}
            />
          ))}
        </div>

        <AddressElement
          key={entityType}
          options={{
            mode: "billing",
            display: {
              name:
                entityType == EntityTypeEnum.Organization
                  ? "organization"
                  : "full",
            },
            defaultValues: {
              address: account.address
                ? {
                    line1: account.address.line1,
                    line2: account.address.line2,
                    city: account.address.city,
                    state: account.address.county,
                    postal_code: account.address.postalCode,
                    country: account.address.country ?? "",
                  }
                : undefined,
              name:
                entityType == EntityTypeEnum.Organization
                  ? `${account.name}`
                  : account.primaryUser
                    ? `${account.primaryUser.firstName} ${account.primaryUser.lastName}`
                    : "",
            },
          }}
        />
      </div>

      <div className={cardStyles}>
        <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon icon="HiDocumentText" isFilled />

          <h2>
            {t("checkoutForm_invoiceEmail.heading", "Invoicing information")}
          </h2>
        </div>

        <Input
          id="invoiceEmail"
          {...register("invoiceEmail")}
          label={t(
            "checkoutForm_creditCard.invoiceEmail.label",
            "Invoice email"
          )}
          hint={t("checkoutForm_creditCard.invoiceEmail.hint", "Optional")}
          errorMessage={errors.invoiceEmail?.message}
          fullWidth
        />
      </div>

      {hasPaymentMethods && (
        <CheckBox
          id="set-as-default"
          name="set-as-default"
          label={t(
            "checkoutForm_creditCard.setAsDefault.label",
            "Set as default"
          )}
          checked={isDefault}
          onChange={(event) => setIsDefault(event.target.checked)}
        />
      )}

      <Button id="checkout-submit_form" type="submit" disabled={isLoading}>
        {submitLabel ?? t("checkoutForm_creditCard.submit", "Subscribe")}
      </Button>

      {errorMessage && <div className={tw("text-error")}>{errorMessage}</div>}
    </form>
  );
};
