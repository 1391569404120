import { Icon } from "components/common/basic";
import { TooltipTarget } from "components/common/composite";
import { TierFragment } from "graphql/fragments";
import { IdentityCountryCodeEnum, PriceCurrencyEnum } from "graphql/types";
import { Trans, useTranslation } from "translations";
import { getFlagFromCountry, getProductName } from "utils";
import { tw } from "utils/tw";

import PlanAction from "./PlanAction";

interface Props {
  description: string;
  purpose: string;
  tier: TierFragment;
  billingCycle?: string;
  planPrefix: string;
  features: string[];
  isPopular?: boolean;
  currentPrice?: TierFragment;
  onSelect?: (price: TierFragment) => void;
}
export default ({
  description,
  purpose,
  tier,
  billingCycle,
  planPrefix,
  features,
  isPopular = false,
  currentPrice,
  onSelect,
}: Props): JSX.Element => {
  const { t } = useTranslation("login");

  return (
    <li
      className={tw(
        "flex-1",
        "flex",
        "flex-col",
        "rounded-lg",
        "overflow-hidden",
        isPopular ? "bg-blue-900" : "bg-white",
        isPopular
          ? tw("outline", "outline-4", "outline-blue-900")
          : tw("border", "border-deepBlue-50"),
        { "lg:mt-10": !isPopular }
      )}
    >
      {isPopular && (
        <span
          className={tw(
            "text-white",
            "bg-blue-900",
            "text-center",
            "uppercase",
            "font-bold",
            "text-sm",
            "p-2",
            "pb-3"
          )}
        >
          {t("planSelection.plan.popular", "Most popular")}
        </span>
      )}

      <div
        className={tw(
          "h-full",
          "p-6",
          "pb-8",
          "flex",
          "flex-col",
          "space-y-8",
          "bg-white",
          "rounded-lg",
          { [tw("border", "border-deepBlue-50")]: isPopular }
        )}
      >
        <div className={tw("space-y-2")}>
          <h2 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
            {getProductName(tier.type, t)}
          </h2>
          <p className={tw("text-deepBlue-700", "h-24", "overflow-hidden")}>
            {description}
          </p>
        </div>

        <div className={tw("space-y-1", { "opacity-0": tier.amount === -1 })}>
          <p className={tw("text-deepBlue-900", "text-4xl", "font-extrabold")}>
            <Trans
              ns="login"
              i18nKey="planSelection.plan.price"
              defaults="{{ price }},- <0>{{ currency }}</0>"
              components={[<span className={tw("text-lg", "font-regular")} />]}
              values={{ price: tier.amount / 100, currency: tier.currency }}
            />
          </p>

          <p className={tw("h-4", "text-deepBlue-700", "text-xs")}>
            {billingCycle}
          </p>
        </div>

        <PlanAction
          currentPrice={currentPrice}
          tier={tier}
          onSelect={onSelect}
        />

        <div className={tw("h-full", "flex", "flex-col", "space-y-4")}>
          <p className={tw("h-4", "text-deepBlue-500", "text-sm")}>{purpose}</p>

          <div className={tw("flex", "space-x-2")}>
            <Icon
              icon={tier.members === 1 ? "FaUser" : "FaUsers"}
              isFilled
              color="text-deepBlue-700"
              size={24}
            />
            <span className={tw("font-bold", "text-deepBlue-900")}>
              {t(`${planPrefix}.teamMemberLimit`, {
                count: tier.members ?? 0,
                defaultValue_zero: "Unlimited team members",
                defaultValue_one: "{{ count }} team member",
                defaultValue_other: "{{ count }} team members",
              })}
            </span>
          </div>

          <ul className={tw("flex-1", "space-y-2")}>
            {features.map((feature) => (
              <li key={feature} className={tw("flex", "space-x-2")}>
                <Row
                  label={t(`${planPrefix}.features.${feature}.label`, feature)}
                  tooltip={{
                    id: `${planPrefix}-${feature}`,
                    content: t(
                      `${planPrefix}.features.${feature}.content`,
                      `Description of ${feature}`
                    ),
                  }}
                />
              </li>
            ))}
          </ul>

          {tier.currency === PriceCurrencyEnum.Nok && tier.amount !== 0 && (
            <div className={tw("p-2", "rounded-md", "bg-silver", "space-y-4")}>
              <div className={tw("space-y-1")}>
                <div className={tw("flex", "space-x-2", "items-center")}>
                  <Icon
                    icon="HiShieldCheck"
                    isFilled
                    color="text-deepBlue-500"
                    size={20}
                  />
                  <span className={tw("text-deepBlue-900")}>
                    {t(
                      `${planPrefix}.norwayOnly.crcProcess.label`,
                      "CRC-process"
                    )}
                  </span>
                </div>
                <div className={tw("flex", "space-x-2", "items-center")}>
                  <img
                    className={tw("m-0.5", "w-4", "h-4", "object-cover")}
                    src={getFlagFromCountry(IdentityCountryCodeEnum.No)}
                    alt="flag"
                  />

                  <span className={tw("text-deepBlue-900")}>
                    {t(
                      `${planPrefix}.norwayOnly.norwayOnly.label`,
                      "Norway only"
                    )}
                  </span>
                </div>
              </div>

              <ul className={tw("space-y-1")}>
                {[
                  {
                    label: t(
                      `${planPrefix}.norwayOnly.templates.label`,
                      "Ready made template"
                    ),
                    tooltip: {
                      id: `${planPrefix}-norwayOnly_templates`,
                      content: t(
                        `${planPrefix}.norwayOnly.templates.content`,
                        "Ready made template"
                      ),
                    },
                  },
                  {
                    label: t(
                      `${planPrefix}.norwayOnly.dCheck.label`,
                      "D-check"
                    ),
                    tooltip: {
                      id: `${planPrefix}-norwayOnly_dCheck`,
                      content: t(
                        `${planPrefix}.norwayOnly.dCheck.content`,
                        "D-check"
                      ),
                    },
                  },
                  {
                    label: t(
                      `${planPrefix}.norwayOnly.crcValidation.label`,
                      "Criminal Record validation"
                    ),
                  },
                  {
                    label: t(
                      `${planPrefix}.norwayOnly.eLearning.label`,
                      "E-learning"
                    ),
                  },
                  ...(tier.amount === -1
                    ? [
                        {
                          label: t(
                            `${planPrefix}.norwayOnly.unlimited.label`,
                            "Unlimited candidates"
                          ),
                          tooltip: {
                            id: `${planPrefix}-norwayOnly_unlimited`,
                            content: t(
                              `${planPrefix}.norwayOnly.unlimited.content`,
                              "Unlimited candidates"
                            ),
                          },
                        },
                      ]
                    : []),
                ].map((props) => (
                  <li key={props.label} className={tw("flex", "space-x-2")}>
                    <Row {...props} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

const Row = (props: {
  label: string;
  tooltip?: { id: string; content: string };
}) => (
  <>
    <span className={tw("text-deepBlue-900", "flex-1")}>{props.label}</span>

    {props.tooltip && (
      <span className={tw("pt-0.5")}>
        <TooltipTarget id={props.tooltip.id} content={props.tooltip.content}>
          <Icon
            icon="HiInformationCircle"
            isFilled
            color="text-deepBlue-500"
            size={20}
          />
        </TooltipTarget>
      </span>
    )}
  </>
);
