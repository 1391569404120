import Plans from "components/login/pages/PlanSelection/Plans";
import { SubscriptionFragment, TierFragment } from "graphql/fragments";
import { tw } from "utils/tw";

interface Props {
  subscription: SubscriptionFragment;
  onSelect: (tier: TierFragment) => void;
}

export default ({ subscription, onSelect }: Props): JSX.Element => (
  <div className={tw("w-full", "space-y-8")}>
    <Plans
      currency={subscription.tier.currency}
      interval={subscription.tier.interval}
      currentPlan={subscription.tier}
      onSelect={onSelect}
    />
  </div>
);
