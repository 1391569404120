import { TierFragment } from "graphql/fragments";
import { Trans, useTranslation } from "translations";
import { getProductName } from "utils";
import { tw } from "utils/tw";

import PlanAction from "./PlanAction";

interface Props {
  price: TierFragment;
  billingCycle?: string;
  currentPrice?: TierFragment;
  onSelect?: (price: TierFragment) => void;
}
export default ({
  price,
  billingCycle,
  currentPrice,
  onSelect,
}: Props): JSX.Element => {
  const { t } = useTranslation("login");

  return (
    <div
      className={tw(
        "p-6",
        "pb-8",
        "flex",
        "flex-col",
        "space-y-9",
        "bg-white",
        "rounded-lg"
      )}
    >
      <h2 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
        {getProductName(price.type, t)}
      </h2>

      <div className={tw("space-y-1", { "opacity-0": price.amount === -1 })}>
        <p className={tw("text-deepBlue-900", "text-4xl", "font-extrabold")}>
          <Trans
            ns="login"
            i18nKey="planSelection.plan.price"
            defaults="{{ price }},- <0>{{ currency }}</0>"
            components={[<span className={tw("text-lg", "font-regular")} />]}
            values={{ price: price.amount / 100, currency: price.currency }}
          />
        </p>

        <p className={tw("h-4", "text-deepBlue-700", "text-xs")}>
          {billingCycle}
        </p>
      </div>

      <PlanAction
        currentPrice={currentPrice}
        tier={price}
        onSelect={onSelect}
      />
    </div>
  );
};
