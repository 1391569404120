import { useContext, useEffect } from "react";
import { HiOutlineUserGroup, HiPlus } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";

import { Button } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import MembersTableWithFilters from "./MembersTableWithFilters";

export default (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  const limit = session?.account.subscription?.tier.members;
  const usage = session?.account.subscription?.usage;
  const hasReachedLimit = limit && usage ? limit <= usage : false;
  const addMember = () =>
    openPopup(
      hasReachedLimit
        ? { id: "UpdateSubscription", props: {} }
        : { id: "TeamMember" }
    );

  useEffect(() => {
    if (searchParams.get("popup") === "TeamMember") addMember();
  }, [searchParams]);

  return (
    <div className={tw("space-y-4")}>
      <div
        className={tw(
          "mb-4",
          "flex",
          "flex-col",
          "w-full",
          "gap-4",
          "lg:flex-row",
          "lg:justify-between",
          "lg:items-start"
        )}
      >
        <div className={tw("flex", "items-center")}>
          <HiOutlineUserGroup
            size={24}
            className={tw("mr-2", "text-deepBlue-900")}
          />

          <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
            {t("accountSettings_org.team.members.header", "Members")}
          </h2>
        </div>

        {session?.account.canManageDepartments && (
          <div>
            <Button
              id="accountSettings-add_member"
              size="md"
              LeadingIcon={HiPlus}
              onClick={addMember}
            >
              {t(
                "accountSettings_org.team.members.action.addTeamMember",
                "New member"
              )}
            </Button>
          </div>
        )}
      </div>

      <MembersTableWithFilters />
    </div>
  );
};
