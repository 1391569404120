import { useContext } from "react";

import { Button, Icon } from "components/common/basic";
import { Banner } from "components/common/composite";
import { SubscriptionFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { getProductName, onDateToMoment } from "utils";
import { tw } from "utils/tw";

export default ({
  usage,
  tier: { type, interval, amount, currency, members },
  currenPeriodStart,
  currentPeriodEnd,
}: SubscriptionFragment): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { openPopup } = useContext(PopupContext);

  return (
    <div
      className={tw(
        "w-full",
        "bg-white",
        "shadow-md",
        "rounded-lg",
        "overflow-hidden"
      )}
    >
      <div className={tw("p-6", "bg-silver", "space-y-2")}>
        <div
          className={tw("flex", "justify-between", "space-x-4", "items-center")}
        >
          <h2 className={tw("text-deepBlue-900", "text-2xl", "font-extrabold")}>
            {getProductName(type, t)}
          </h2>

          <div className={tw("flex", "items-end", "space-x-1")}>
            <Trans
              ns="login"
              i18nKey={`accountSettings_org.subscriptions.subscription_${interval.toLowerCase()}.price`}
              defaults={`<0>${interval} {{ price }},-</0><1>{{ currency }}</1>`}
              components={[
                <p
                  className={tw(
                    "text-deepBlue-900",
                    "text-2xl",
                    "font-extrabold"
                  )}
                />,
                <p
                  className={tw("text-deepBlue-900", "text-lg", "font-bold")}
                />,
              ]}
              values={{ price: amount / 100, currency }}
            />
          </div>
        </div>

        <p className={tw("text-deepBlue-900")}>
          {t(
            "accountSettings_org.subscriptions.subscription.period",
            "Subscription period: {{ from }} - {{ to }}",
            {
              from: onDateToMoment(currenPeriodStart).format("MMM. YYYY"),
              to: onDateToMoment(currentPeriodEnd).format("MMM. YYYY"),
            }
          )}
        </p>

        <button
          className={tw("underline", "text-deepBlue-900", "text-sm")}
          onClick={() =>
            openPopup({
              id: "UpdateSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.viewDetails",
            "View details"
          )}
        </button>
      </div>

      <div
        className={tw(
          "p-6",
          "pt-8",
          "flex",
          "flex-col",
          "space-y-8",
          "items-start"
        )}
      >
        <div
          className={tw(
            "w-full",
            "p-4",
            "space-y-2",
            "rounded-lg",
            "border",
            "border-deepBlue-50"
          )}
        >
          <div className={tw("flex", "items-center", "space-x-2")}>
            <Icon icon="FaUsers" isFilled size={24} />

            <p className={tw("text-deepBlue-900", "font-bold")}>
              {t(
                "accountSettings_org.subscriptions.subscription.details.limit",
                {
                  count: members ?? 0,
                  defaultValue_zero: "Unlimited team members included",
                  defaultValue_one: "{{ count }} team member included",
                  defaultValue_other: "{{ count }} team members included",
                }
              )}
            </p>
          </div>

          <p className={tw("text-blue-900")}>
            {members ? (
              <Trans
                ns="login"
                i18nKey="accountSettings_org.subscriptions.subscription.details.usage"
                defaults="You have added <0>{{ usage }}</0> of <0>{{ limit }}</0> team members"
                components={[<strong className={tw("font-bold")} />]}
                values={{ usage, limit: members }}
              />
            ) : (
              <Trans
                ns="login"
                i18nKey="accountSettings_org.subscriptions.subscription.details.usage.unlimited"
                defaults="You have added <0>{{ usage }}</0> team members"
                components={[<strong className={tw("font-bold")} />]}
                values={{ usage }}
              />
            )}
          </p>
        </div>

        {members && usage >= members && (
          <Banner
            variant="info"
            title={t(
              "accountSettings_org.subscriptions.subscription.maxUsageBanner.title",
              "You have maxed out your subscription plan"
            )}
            body={t(
              "accountSettings_org.subscriptions.subscription.maxUsageBanner.body",
              "Upgrade to a higher subscription plan to add more team members and benefit from more features."
            )}
          />
        )}

        <Button
          id="orgAccountSettings_subscriptions-upgradeSubscription"
          onClick={() =>
            openPopup({
              id: "UpdateSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.upgrade",
            "Upgrade plan"
          )}
        </Button>

        <button
          className={tw("underline", "text-deepBlue-900", "text-sm")}
          onClick={() =>
            openPopup({
              id: "CancelSubscription",
              props: {},
            })
          }
        >
          {t(
            "accountSettings_org.subscriptions.subscription.cancel",
            "Cancel subscription"
          )}
        </button>
      </div>
    </div>
  );
};
