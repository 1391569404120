import { HiChevronRight } from "react-icons/hi";

import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  currentStep: number;
}

export default ({ currentStep }: Props): JSX.Element => {
  const { t } = useTranslation("account");

  const crumbStyles = tw(
    "flex",
    "items-center",
    "space-x-2",
    "text-sm",
    "font-semibold"
  );

  return (
    <div className={tw("flex", "flex-wrap", "gap-y-1", "gap-x-2")}>
      <div className={tw(crumbStyles)}>
        <span
          className={tw(
            currentStep > 0 ? "text-deepBlue-900" : "text-deepBlue-500"
          )}
        >
          {t("popup.updateSubscription.progress.step1", "Subscription plan")}
        </span>

        <HiChevronRight size={20} className={tw("text-deepBlue-500")} />
      </div>

      <div className={tw(crumbStyles)}>
        <span
          className={tw(
            currentStep > 1 ? "text-deepBlue-900" : "text-deepBlue-500"
          )}
        >
          {t("popup.updateSubscription.progress.step2", "Payment")}
        </span>

        <HiChevronRight size={20} className={tw("text-deepBlue-500")} />
      </div>

      <div className={tw(crumbStyles)}>
        <span
          className={tw(
            currentStep > 2 ? "text-deepBlue-900" : "text-deepBlue-500"
          )}
        >
          {t("popup.updateSubscription.progress.step3", "Confirmation")}
        </span>
      </div>
    </div>
  );
};
