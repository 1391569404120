import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { Button, Spinner } from "components/common/basic";
import { TierFragment } from "graphql/fragments";
import { useTiersQuery } from "graphql/queries";
import {
  PriceCurrencyEnum,
  PriceIntervalEnum,
  TierTypeEnum,
} from "graphql/types";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import FeatureTable from "./FeatureTable";
import Plan from "./Plan";

interface Props {
  currency: PriceCurrencyEnum;
  interval: PriceIntervalEnum;
  currentPlan?: TierFragment;
  onSelect?: (tier: TierFragment) => void;
  nonProfit?: boolean;
}

export default ({
  currency,
  interval,
  currentPlan,
  onSelect,
}: Props): JSX.Element => {
  const [isShowingFeatures, setIsShowingFeatures] = useState(false);

  const { tiers, isLoading } = useTiersQuery({
    filter: { currency, interval },
  });
  const sortedTiers = tiers.sort((a, b) => (a.amount > b.amount ? 1 : -1));

  const { t } = useTranslation("login");

  const getFeatures = (index: number, tier: TierTypeEnum) => {
    const prefix = tier.toLowerCase();

    switch (index) {
      case -1:
        return [`${prefix}-1`, `${prefix}-2`];

      case 1:
        return [
          `${prefix}-1`,
          `${prefix}-2`,
          `${prefix}-3`,
          `${prefix}-4`,
          `${prefix}-5`,
        ];

      default:
        return [`${prefix}-1`, `${prefix}-2`, `${prefix}-3`, `${prefix}-4`];
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <ul className={tw("flex", "flex-col", "lg:flex-row", "gap-4")}>
        {sortedTiers.map((tier, index) => {
          return (
            <Plan
              key={tier.id}
              tier={tier}
              currentPrice={currentPlan}
              onSelect={onSelect}
              planPrefix={`planSelection.plan_${tier.type.toLowerCase()}`}
              description={t(
                `planSelection.plan_${tier.type.toLowerCase()}.description`,
                `Description of ${tier.type}`
              )}
              purpose={t(
                `planSelection.plan_${tier.type.toLowerCase()}.purpose`,
                "Elevate your impact!"
              )}
              billingCycle={
                tier.amount === 0
                  ? t(
                      "planSelection.plan.billingCycle.free",
                      "Upgrade whenever you want"
                    )
                  : interval === PriceIntervalEnum.Year
                    ? t("planSelection.plan.billingCycle.annual", "Per year")
                    : interval === PriceIntervalEnum.Month
                      ? t("planSelection.plan.billingCycle.annual", "Per month")
                      : undefined
              }
              features={getFeatures(index, tier.type)}
              isPopular={!currentPlan && index === 2}
            />
          );
        })}

        <Plan
          planPrefix={`planSelection.plan_enterprise`}
          description={t(
            "planSelection.plan_enterprise.description",
            "Contact us to get a custom offer that suits your organisations requirements, including API integrations to ensure that our platforms seamlessly ensure peace of mind."
          )}
          purpose={t(
            "planSelection.plan_enterprise.purpose",
            "Everything in Premium"
          )}
          tier={{
            type: TierTypeEnum.Enterprise,
            id: "enterprise",
            amount: -1,
            currency,
            interval,
          }}
          features={getFeatures(-1, TierTypeEnum.Enterprise)}
        />
      </ul>

      <div className={tw("hidden", "md:flex", "justify-center")}>
        <Button
          id="plan_selection-compare_features"
          TrailingIcon={isShowingFeatures ? HiChevronUp : HiChevronDown}
          variant="secondary"
          size="md"
          onClick={() => setIsShowingFeatures(!isShowingFeatures)}
        >
          {t(
            "planSelection.features.button.showFeatures",
            "Compare all features"
          )}
        </Button>
      </div>

      {isShowingFeatures && (
        <FeatureTable
          prices={sortedTiers}
          currentPlan={currentPlan}
          onSelect={onSelect}
        />
      )}
    </>
  );
};
