import { Icon } from "components/common/basic";
import { TierFragment } from "graphql/fragments";
import { PriceIntervalEnum } from "graphql/types";
import { useTranslation } from "translations";
import { getProductName } from "utils";
import { tw } from "utils/tw";

interface Props {
  tier: TierFragment;
  isCompact?: boolean;
}

export default ({
  tier: { amount, type, interval, members },
  isCompact,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const planPrefix = `pricePlanSummary.plan_${type.toLowerCase()}`;
  const billingCycle =
    interval === PriceIntervalEnum.Year
      ? t("pricePlanSummary.plan.billingCycle.yearly", "Per year")
      : interval === PriceIntervalEnum.Month
        ? t(`pricePlanSummary.plan.billingCycle.monthly`, "Per month")
        : undefined;

  if (isCompact)
    return (
      <div
        className={tw(
          "w-full",
          "p-6",
          "bg-white",
          "shadow-md",
          "rounded-lg",
          "space-y-4"
        )}
      >
        <div className={tw("flex", "items-center", "justify-between")}>
          <p className={tw("text-2xl", "font-extrabold", "text-deepBlue-900")}>
            {getProductName(type, t)}
          </p>

          <p className={tw("font-semibold", "text-deepBlue-900")}>
            {t(`${planPrefix}.price`, "{{ price }},- NOK", {
              price: amount / 100,
            })}
          </p>
        </div>

        {amount > 0 && (
          <p className={tw("text-deepBlue-700")}>{billingCycle}</p>
        )}
      </div>
    );

  return (
    <div
      className={tw(
        "w-full",
        "px-4",
        "py-6",
        "bg-white",
        "shadow-md",
        "rounded-lg",
        "space-y-6"
      )}
    >
      <div className={tw("space-y-2")}>
        {/* <div className={tw("flex", "items-center", "space-x-2")}>
          <Icon
            icon={"HiShieldCheck"}
            isFilled
            color="text-blue-700"
            size={20}
          />

          <p className={tw("text-lg", "font-bold", "text-deepBlue-700")}>
            {product.name}
          </p>
        </div> */}

        <div className={tw("flex", "items-center", "justify-between")}>
          <p className={tw("text-2xl", "font-extrabold", "text-deepBlue-900")}>
            {getProductName(type, t)}
          </p>

          <p className={tw("font-semibold", "text-deepBlue-900")}>
            {t(`${planPrefix}.price`, "{{ price }},- NOK", {
              price: amount / 100,
            })}
          </p>
        </div>

        <p className={tw("text-deepBlue-700")}>
          {t("pricePlanSummary.plan.selected", "Selected plan")}
        </p>
      </div>

      <div className={tw("flex", "space-x-2")}>
        <Icon icon="FaUsers" isFilled color="text-deepBlue-700" size={24} />

        <span className={tw("font-bold", "text-deepBlue-900")}>
          {t(`${planPrefix}.limit`, {
            count: members ?? 0,
            defaultValue_zero: "Unlimited team members included",
            defaultValue_one: "{{ count }} team member included",
            defaultValue_other: "{{ count }} team members included",
          })}
        </span>
      </div>

      {amount > 0 && (
        <div
          className={tw(
            "py-3",
            "px-4",
            "bg-silver",
            "rounded-lg",
            "flex",
            "justify-between"
          )}
        >
          <p className={tw("font-semibold", "text-deepBlue-900")}>
            {t("pricePlanSummary.plan.billingCycle.label", "Subscription")}
          </p>

          <p className={tw("text-deepBlue-700")}>{billingCycle}</p>
        </div>
      )}
    </div>
  );
};
