import { Fragment } from "react/jsx-runtime";

import { Icon } from "components/common/basic";
import { TierFragment } from "graphql/fragments";
import { PriceIntervalEnum, TierTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { TTailwindString, tw } from "utils/tw";

import FeatureTableHeading from "./FeatureTableHeading";

interface Props {
  prices: TierFragment[];
  currentPlan?: TierFragment;
  onSelect?: (price: TierFragment) => void;
}

export default ({ prices, currentPlan, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation("login");

  const featuresByPlan = [...Array(20)]
    .map((_value, index) => `feature-${index + 1}`)
    .map((label) => ({
      label,
      plans: [...prices.map(({ type }) => type === TierTypeEnum.Premium), true],
    }));

  const gridCols = `grid-cols-${prices.length + 2}` as TTailwindString;

  return (
    <>
      {/* HEADINGS */}
      <div
        className={tw(
          "sticky",
          currentPlan ? "top-0" : "top-16",
          "grid",
          gridCols,
          "bg-white"
        )}
      >
        <span />

        {prices.map((price) => (
          <FeatureTableHeading
            key={price.id}
            billingCycle={
              price.interval === PriceIntervalEnum.Year
                ? t(
                    "planSelection.features.header.billingCycle.yearly",
                    "Per year"
                  )
                : price.interval === PriceIntervalEnum.Month
                  ? t(
                      `planSelection.features.header.billingCycle.monthly`,
                      "per month"
                    )
                  : undefined
            }
            price={price}
            currentPrice={currentPlan}
            onSelect={onSelect}
          />
        ))}

        <FeatureTableHeading
          price={{
            id: "enterprise",
            amount: -1,
            currency: prices[0].currency,
            interval: prices[0].interval,
            type: TierTypeEnum.Enterprise,
          }}
        />
      </div>

      {/* CONTENT */}
      <div className={tw("space-y-4")}>
        <div className={tw("flex", "space-x-2")}>
          <Icon icon="HiSparkles" isFilled size={20} color="text-gray-900" />

          <h3 className={tw("font-bold")}>TITLE_1</h3>
        </div>

        <div
          className={tw(
            "grid",
            gridCols,
            "gap-y-px",
            "bg-deepBlue-50",
            "border-y",
            "border-deepBlue-50"
          )}
        >
          {featuresByPlan.map(({ label, plans }) => (
            <Fragment key={label}>
              <span className={tw("py-4", "bg-white", "text-deepBlue-900")}>
                {label}
              </span>

              {plans.map((hasFeature, index) => (
                <span
                  key={index}
                  className={tw("py-4", "bg-white", "flex", "justify-center")}
                >
                  {hasFeature ? (
                    <Icon
                      icon="HiCheckCircle"
                      isFilled
                      size={20}
                      color="text-success"
                    />
                  ) : (
                    <Icon
                      icon="HiX"
                      isFilled
                      size={20}
                      color="text-deepBlue-100"
                    />
                  )}
                </span>
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
