import { useTranslation } from "react-i18next";
import {
  HiExclamation,
  HiLockClosed,
  HiOutlineAdjustments,
  HiOutlineCash,
  HiOutlineCollection,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
  HiOutlineFingerPrint,
  HiOutlinePencil,
  HiOutlineSparkles,
  HiOutlineSpeakerphone,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { SessionFragment } from "graphql/fragments";
import { AccountTypeEnum } from "graphql/types";
import { GetRoutePathProps, Module, useRoutePaths } from "utils";
import { symbolDark } from "utils/brand";

import { MenuItem, MenuItemGroup } from "./MenuList/types";

interface Props {
  session?: SessionFragment;
}

export default ({ session }: Props): MenuItem[] => {
  const { t } = useTranslation("common");

  const getRoutePath = useRoutePaths();
  const { pathname } = useLocation();

  if (!session) return [];

  return session.accounts.reduce(
    (
      acc: MenuItem[],
      {
        slug,
        accountType,
        features,
        uiFlags,
        name,
        email,
        symbolDisplayPath,
        hasFreelanceProfiles,
        primaryUser,
        deletionScheduledOn,
        canManage,
      }
    ) => {
      const isTeam = accountType === AccountTypeEnum.Team;
      const logoImg = symbolDisplayPath
        ? {
            src: `${process.env.REACT_APP_API_URL}${symbolDisplayPath}`,
            alt: "Profile image",
          }
        : undefined;

      const getAccountPath: <T extends Module>(
        route: GetRoutePathProps<T>
      ) => string = (route) =>
        getRoutePath(route, { account: { slug, type: accountType } });

      const pathnameWithAccount = (() => {
        const [prefix, version, locale, _type, _slug, ...rest] =
          pathname.split("/");

        return [
          prefix,
          version,
          locale,
          session.account.accountType.toLowerCase(),
          session.account.slug,
          ...rest,
        ].join("/");
      })();

      const isInvoicemore =
        pathnameWithAccount.includes(
          getAccountPath({ module: "invoicemore", routeName: "PAYOUT_SERVICE" })
        ) ||
        pathnameWithAccount.includes(
          getAccountPath({
            module: "invoicemore",
            routeName: "INVOICE",
            arg1: "",
          })
        ) ||
        pathnameWithAccount.includes(
          getAccountPath({
            module: "invoicemore",
            routeName: "INVOICE_CREATION",
          })
        );

      const isSignmore =
        pathnameWithAccount.includes(
          getAccountPath({ module: "signmore", routeName: "DOCUMENTS" })
        ) ||
        pathnameWithAccount.includes(
          getAccountPath({
            module: "signmore",
            routeName: "CREATE_DOCUMENT_WIZARD",
          })
        );

      const isTellmore = pathnameWithAccount.includes(
        getAccountPath({ module: "tellmore", routeName: "WB_SETTINGS" })
      );

      const hasInvoicemore =
        features.invoices !== false && hasFreelanceProfiles;
      const hasPayoutmore = features.payouts !== false;
      const hasSignmore = features.eSignatures !== false;

      const scheduledDeletionItems = [
        {
          id: `sideMenu-go_to_people:${slug}` as const,
          Icon: HiExclamation,
          iconClassName: "text-error",
          isDestructive: true,
          label: t(
            "layout.sideMenu.link.scheduledDeletion",
            "Deletion scheduled"
          ),
          to: getAccountPath({
            module: "account",
            routeName: "SCHEDULED_DELETION",
          }),
          isActive: pathnameWithAccount.includes(
            getAccountPath({
              module: "account",
              routeName: "SCHEDULED_DELETION",
            })
          ),
        },
      ];

      if (isTeam) {
        const hasTellMore = canManage && features.whistleblowing !== false;
        const hasCheckmore = features.checks !== false;
        const hasRecruitmore = features.workflows !== false;
        const hasPeople = hasSignmore || hasCheckmore;

        const partnerGroup: MenuItemGroup = {
          id: `sideMenu-partner_group:${slug}`,
          variant: "sideMenu",
          isOpenByDefault: slug === session.account.slug,
          label: name ?? email,
          image: {
            src: logoImg ? logoImg.src : symbolDark,
            alt: "Partner logo",
          },
          items: deletionScheduledOn
            ? scheduledDeletionItems
            : [
                ...(hasCheckmore && hasTellMore
                  ? [
                      {
                        id: `sideMenu-go_to_dashboard:${slug}` as const,
                        Icon: HiOutlineSparkles,
                        label: t(
                          "layout.sideMenu_team.link.dashboard",
                          "Get started"
                        ),
                        to: getAccountPath({
                          module: "account",
                          routeName: "DASHBOARD",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "account",
                            routeName: "DASHBOARD",
                          })
                        ),
                      },
                    ]
                  : []),
                ...(hasPeople
                  ? [
                      {
                        id: `sideMenu-go_to_people:${slug}` as const,
                        Icon: HiOutlineUserGroup,
                        label: t(
                          "layout.sideMenu_team.link.people",
                          "Overview"
                        ),
                        to: getAccountPath({
                          module: "account",
                          routeName: "OVERVIEW_PEOPLE",
                        }),
                        isActive:
                          pathnameWithAccount.includes(
                            getAccountPath({
                              module: "account",
                              routeName: "OVERVIEW",
                            })
                          ) ||
                          pathnameWithAccount.includes(
                            getAccountPath({
                              module: "tellmore",
                              routeName: "WB_REPORT",
                              arg1: "",
                            })
                          ),
                      },
                    ]
                  : []),
                {
                  id: `sideMenu-go_to_settings:${slug}` as const,
                  Icon: HiOutlineAdjustments,
                  label: t(
                    "layout.sideMenu_team.link.settings",
                    "My organisation"
                  ),
                  to: getAccountPath({
                    module: "account",
                    routeName: "SETTINGS",
                  }),
                  isActive: pathnameWithAccount.includes(
                    getAccountPath({ module: "account", routeName: "SETTINGS" })
                  ),
                },

                {
                  text: t("layout.sideMenu.tools", "tools"),
                  isSubHeading: true,
                },
                ...(hasRecruitmore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.recruitmore", "recruitmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_workflows:${slug}` as const,
                        Icon: HiOutlineCollection,
                        label: t(
                          "layout.sideMenu_team.link.workflows",
                          "Workflows"
                        ),
                        to: getAccountPath({
                          module: "recruitmore",
                          routeName: "JOBS",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "recruitmore",
                            routeName: "JOBS",
                          })
                        ),
                      },
                    ]
                  : []),
                ...(hasCheckmore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.checkmore", "checkmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_checks:${slug}` as const,
                        Icon: HiOutlineFingerPrint,
                        label: t(
                          "layout.sideMenu_team.link.checkmore",
                          "Checkmore"
                        ),
                        to: getAccountPath({
                          module: "checkmore",
                          routeName: "CHECK_TYPE_SELECTION",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "checkmore",
                            routeName: "CHECKMORE",
                          })
                        ),
                      },
                    ]
                  : []),
                ...(hasTellMore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.tellmore", "tellmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_wbSettings:${slug}` as const,
                        Icon: HiOutlineSpeakerphone,
                        label: t(
                          "layout.sideMenu_team.link.tellmore",
                          "Tellmore"
                        ),
                        to: getAccountPath({
                          module: "tellmore",
                          routeName: "WB_SETTINGS",
                        }),
                        isActive: isTellmore,
                      },
                    ]
                  : []),
                ...(hasSignmore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.signmore", "signmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_eSignatures:${slug}` as const,
                        Icon: HiOutlinePencil,
                        label: t(
                          "layout.sideMenu_team.link.signmore",
                          "Signmore"
                        ),
                        to: getAccountPath({
                          module: "signmore",
                          routeName: "DOCUMENTS",
                        }),
                        isActive: isSignmore,
                      },
                    ]
                  : []),
                ...(hasInvoicemore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.invoicemore", "invoicemore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_invoices:${slug}` as const,
                        Icon: HiOutlineDocumentText,
                        label: t(
                          "layout.sideMenu_team.link.invoices",
                          "Invoices"
                        ),
                        to: getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE",
                        }),
                        isActive: isInvoicemore,
                      },
                    ]
                  : []),
                ...(hasPayoutmore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.payoutmore", "payoutmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_payouts:${slug}` as const,
                        Icon: HiOutlineCurrencyDollar,
                        label: t(
                          "layout.sideMenu_team.link.payouts",
                          "Payouts"
                        ),
                        to: getAccountPath({
                          module: "payoutmore",
                          routeName: "PURCHASES",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "payoutmore",
                            routeName: "PURCHASES",
                          })
                        ),
                      },
                    ]
                  : []),
              ],
        };

        return [
          ...acc,
          "DIVIDER",
          {
            text: t("layout.sideMenu_team.groupTitle.freeSpace", "Team"),
          },
          partnerGroup,
        ];
      }

      const hasCheckmore = uiFlags.hasReceivedCheckLists;
      const hasTellMore = process.env.REACT_APP_BRAND === "manymore";

      const workerName =
        name ||
        (primaryUser?.firstName &&
          primaryUser?.lastName &&
          `${primaryUser.firstName} ${primaryUser.lastName}`);

      const workerGroup: MenuItemGroup = {
        id: `sideMenu-worker_group:${slug}`,
        variant: "sideMenu",
        isOpenByDefault: slug === session.account.slug,
        label: workerName ?? email,
        ...(logoImg ? { image: logoImg } : { Icon: HiOutlineUserCircle }),
        items: deletionScheduledOn
          ? scheduledDeletionItems
          : [
              // {
              //   id: `sideMenu-go_to_dashboard:${slug}` as const,
              //   Icon: HiOutlineSparkles,
              //   label: t(
              //     "layout.sideMenu_personal.link.dashboard",
              //     "Get started"
              //   ),
              //   to: getAccountPath({
              //     module: "account",
              //     routeName: "DASHBOARD",
              //   }),
              //   isActive: pathnameWithAccount.includes(
              //     getAccountPath({
              //       module: "account",
              //       routeName: "DASHBOARD",
              //     })
              //   ),
              // },
              {
                id: `sideMenu-go_to_settings:${slug}` as const,
                Icon: HiOutlineAdjustments,
                label: t("layout.sideMenu_personal.link.settings", "Settings"),
                to: getAccountPath({
                  module: "account",
                  routeName: "SETTINGS",
                }),
                isActive: pathnameWithAccount.includes(
                  getAccountPath({ module: "account", routeName: "SETTINGS" })
                ),
              },

              {
                text: t("layout.sideMenu.tools", "tools"),
                isSubHeading: true,
              },
              ...(hasCheckmore
                ? [
                    // {
                    //   text: t("layout.sideMenu_personal.module.checkmore", "checkmore"),
                    //   isSubHeading: true,
                    // },
                    {
                      id: `sideMenu-go_to_checks:${slug}` as const,
                      Icon: HiOutlineFingerPrint,
                      label: t(
                        "layout.sideMenu_personal.link.checkmore",
                        "Checkmore"
                      ),
                      to: getAccountPath({
                        module: "checkmore",
                        routeName: "CHECKS",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "checkmore",
                          routeName: "CHECKS",
                        })
                      ),
                    },
                  ]
                : []),
              ...(hasTellMore
                ? [
                    // {
                    //   text: t("layout.sideMenu_personal.module.tellmore", "tellmore"),
                    //   isSubHeading: true,
                    // },
                    {
                      id: `sideMenu-go_to_wbSettings:${slug}` as const,
                      Icon: HiOutlineSpeakerphone,
                      label: t(
                        "layout.sideMenu_personal.link.tellmore",
                        "Tellmore"
                      ),
                      to: getAccountPath({
                        module: "tellmore",
                        routeName: "WB_DIRECTORY",
                      }),
                      isActive: isTellmore,
                    },
                  ]
                : []),
              ...(hasSignmore
                ? [
                    // {
                    //   text: t("layout.sideMenu_personal.module.signmore", "signmore"),
                    //   isSubHeading: true,
                    // },
                    {
                      id: `sideMenu-go_to_eSignatures:${slug}` as const,
                      Icon: HiOutlinePencil,
                      label: t(
                        "layout.sideMenu_personal.link.signmore",
                        "Signmore"
                      ),
                      to: getAccountPath({
                        module: "signmore",
                        routeName: "DOCUMENTS",
                      }),
                      isActive: isSignmore,
                    },
                  ]
                : []),
              ...(hasInvoicemore
                ? [
                    // {
                    //   text: t("layout.sideMenu_personal.module.invoicemore", "invoicemore"),
                    //   isSubHeading: true,
                    // },
                    {
                      id: `sideMenu-go_to_payoutService:${slug}` as const,
                      Icon: HiOutlineCash,
                      label: t(
                        "layout.sideMenu_personal.link.payoutService",
                        "Payout Service"
                      ),
                      to: getAccountPath({
                        module: "invoicemore",
                        routeName: "PAYOUT_SERVICE",
                      }),
                      isActive: isInvoicemore,
                    },
                  ]
                : []),
            ],
      };
      return [
        {
          text: t("layout.sideMenu_personal.groupTitle.private", "Private"),
          Icon: HiLockClosed,
        },
        workerGroup,
        ...acc,
      ];
    },
    []
  );
};
