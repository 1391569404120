import { gql } from "@apollo/client";

import { Subscription } from "graphql/types";

import { TIER_FRAGMENT, TierFragment } from "./tier";

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on Subscription {
    tier {
      ...TierFragment
    }
    currentPeriodEnd
    usage
  }
  ${TIER_FRAGMENT}
`;

export interface SubscriptionFragment
  extends Pick<
    Subscription,
    // TODO: Add "currenPeriodStart" to fragment when not null
    "currenPeriodStart" | "currentPeriodEnd" | "usage"
  > {
  tier: TierFragment;
}
