import { HiOutlineCollection } from "react-icons/hi";

import { Spinner } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { useSessionQuery } from "graphql/queries";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { AccountCrumbs } from "../../shared";
// import DateBanners from "./DateBanners";
import SubscriptionCard from "./SubscriptionCard";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useSessionQuery();

  return (
    <Page
      title={t("accountSettings_org.subscriptions.pageTitle", "Subscriptions")}
      className={tw("space-y-8", "max-w-screen-xl")}
    >
      <div className={tw("space-y-4")}>
        <AccountCrumbs
          currentPage={t(
            "accountSettings_org.subscriptions.breadcrumb",
            "Subscriptions"
          )}
        />

        <div
          className={tw(
            "mb-4",
            "flex",
            "flex-col",
            "w-full",
            "gap-4",
            "lg:flex-row",
            "lg:justify-between",
            "lg:items-start"
          )}
        >
          <HeadingWithNotice
            heading={t(
              "accountSettings_org.subscriptions.header",
              "Subscriptions"
            )}
            LeadingIcon={HiOutlineCollection}
            notice={{
              id: "orgAccountSettings_subscriptions-headerNotice",
              heading: t(
                "accountSettings_org.subscriptions.noticeHeader",
                "Subscriptions"
              ),
              body: t(
                "accountSettings_org.subscriptions.noticeDescription",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              ),
            }}
          />
        </div>

        {/* TODO: Show banner when downgraded or cancelled  */}
        {/* <DateBanners account={session.account} /> */}
      </div>

      {session ? (
        session.account.subscription && (
          <SubscriptionCard {...session.account.subscription} />
        )
      ) : (
        <Spinner />
      )}
    </Page>
  );
};
