import { useContext, useState } from "react";

import { RadioPill, Spinner } from "components/common/basic";
import { PaymentMethodFragment } from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import CreditCardForm from "./CreditCardForm";
import InvoiceForm from "./InvoiceForm";
import StripeWrapper from "./StripeWrapper";

enum PaymentMethodEnum {
  CreditCard = "CREDIT_CARD",
  Invoice = "INVOICE",
}

interface Props {
  stripeReturnUrl?: URL;
  isFlat?: boolean;
  hasPaymentMethods?: boolean;
  submitLabel?: string;
  onSuccess?: (paymentMethod: PaymentMethodFragment) => void;
}

export default ({
  stripeReturnUrl,
  isFlat = false,
  hasPaymentMethods = false,
  submitLabel,
  onSuccess,
}: Props): JSX.Element => {
  const [paymentMethod, setPaymentMethod] = useState(
    PaymentMethodEnum.CreditCard
  );

  const { t } = useTranslation("common");
  const { session } = useContext(AuthContext);
  const hasInvoiceOption = session?.account.country === "NO";

  if (!session) return <Spinner />;

  return (
    <div className={tw("space-y-6")}>
      {hasInvoiceOption && (
        <div className={tw("space-x-3")}>
          {[
            {
              label: t("checkoutForm.paymentMethod.creditCard", "Credit card"),
              value: PaymentMethodEnum.CreditCard,
            },
            {
              label: t("checkoutForm.paymentMethod.invoice", "Invoice"),
              value: PaymentMethodEnum.Invoice,
            },
          ].map((option) => (
            <RadioPill
              key={option.value}
              id={option.value}
              label={option.label}
              value={option.value}
              checked={paymentMethod === option.value}
              onChange={() => setPaymentMethod(option.value)}
            />
          ))}
        </div>
      )}

      {paymentMethod === PaymentMethodEnum.Invoice ? (
        <InvoiceForm
          account={session.account}
          isFlat={isFlat}
          submitLabel={submitLabel}
          onSuccess={onSuccess}
        />
      ) : (
        <StripeWrapper>
          <CreditCardForm
            account={session.account}
            stripeReturnUrl={stripeReturnUrl}
            isFlat={isFlat}
            hasPaymentMethods={hasPaymentMethods}
            submitLabel={submitLabel}
            onSuccess={onSuccess}
          />
        </StripeWrapper>
      )}
    </div>
  );
};
