import PaymentMethod from "components/account/pages/accountSettings/organization/PaymentMethods/PaymentMethod";
import { Button, Spinner } from "components/common/basic";
import { CheckoutForm, TierSummary } from "components/common/composite";
import AccountDetails from "components/common/pages/GateKeeper/Checkout/AccountDetails";
import { AccountFragment, TierFragment } from "graphql/fragments";
import { useUpdateSubscriptionMutation } from "graphql/mutations";
import { usePaymentMethodsQuery } from "graphql/queries";
import { useFormatter } from "hooks";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  account: AccountFragment;
  tier: TierFragment;
}

export default ({ account, tier }: Props): JSX.Element => {
  const { paymentMethods } = usePaymentMethodsQuery();

  const { updateSubscription } = useUpdateSubscriptionMutation();
  const { t } = useTranslation("account");
  const { getFormattedAddress } = useFormatter();

  if (!paymentMethods) return <Spinner />;

  const subscribe = () => updateSubscription(tier.id);

  const summary = (
    <div
      className={tw(
        "w-full",
        "rounded-lg",
        "bg-silver",
        "px-4",
        "py-6",
        "xl:p-10",
        "xl:w-auto"
      )}
    >
      <div className={tw("xl:w-80")}>
        <TierSummary tier={tier} />
      </div>
    </div>
  );

  if (paymentMethods.length === 0)
    return (
      <div
        className={tw(
          "flex",
          "flex-wrap-reverse",
          "gap-6",
          "xl:flex-nowrap",
          "xl:gap-24",
          "items-start"
        )}
      >
        <div className={tw("flex-1")}>
          <CheckoutForm
            submitLabel={t(
              "popup.updateSubscription_payment.paymentMethodForm.submit",
              "Subscribe"
            )}
            onSuccess={subscribe}
          />
        </div>

        {summary}
      </div>
    );

  const defaultMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.default
  );
  if (!defaultMethod) return <Spinner />; // This should never happen

  return (
    <div
      className={tw(
        "flex",
        "flex-wrap-reverse",
        "gap-6",
        "xl:flex-nowrap",
        "xl:gap-24"
      )}
    >
      <div className={tw("flex-1", "space-y-8")}>
        {/* TODO: Move to composite */}
        <AccountDetails
          email={account.email}
          organization={`${account.organizationName} / ${account.organizationNumber}`}
          address={account.address && getFormattedAddress(account.address)}
        />

        <div className={tw("space-y-4")}>
          <h2 className={tw("text-deepBlue-900", "text-lg", "font-bold")}>
            {t(
              "popup.updateSubscription_payment.defaultMethod.heading",
              "Default payment method"
            )}
          </h2>

          {/* TODO: Move to composite */}
          <PaymentMethod paymentMethod={defaultMethod} canEdit={false} />
        </div>

        <Button id="subscription_change_popup-save_changes" onClick={subscribe}>
          {t("popup.updateSubscription_payment.subscribe", "Subscribe")}
        </Button>
      </div>

      {summary}
    </div>
  );
};
