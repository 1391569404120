import { gql } from "@apollo/client";

import { Tier } from "graphql/types";

export const TIER_FRAGMENT = gql`
  fragment TierFragment on Tier {
    id
    type
    amount
    currency
    interval
    members
  }
`;

export interface TierFragment
  extends Pick<
    Tier,
    "id" | "type" | "amount" | "currency" | "interval" | "members"
  > {}
