import React, { useEffect, useState } from "react";

import { ActionID, IconType } from "components/common/basic";
import LibraryPopup from "components/common/pages/ComponentLibrary/Popups/LibraryPopup";
import {
  AccountAddress,
  AccountEmail,
  AccountName,
  AccountPhone,
  AddBulkRecipients,
  AddPaymentMethod,
  AddPeople,
  AddTeam,
  ArchivePeople,
  AssignWbHandlers,
  BlockedManualInvoicing,
  CancelDocuments,
  CancelSubscription,
  CheckLog,
  CheckResult,
  CheckmorePrices,
  ClientCreation,
  ConfirmAction,
  DeleteAccount,
  Department,
  DocumentDrawer,
  DraftInvoiceInfo,
  EditPaymentMethod,
  FreelanceProfile,
  JobCreation,
  JobDescription,
  OnboardingVideo,
  OverrideCRC,
  PaymentInfo,
  PhoneNumberVerification,
  RedirectWithMessage,
  ReferencePerson,
  RefuseCheck,
  RemovePeople,
  RoutineGuidelines,
  Signature,
  StepLoaderPopup,
  TeamInfo,
  TeamMember,
  UnarchivePeople,
  UpdateSubscription,
  UpdateWbReport,
  ViewBankAccount,
  WbReport,
} from "components/common/popups";
import AddBankAccount from "components/common/popups/AddBankAccount";
import {
  AddPeopleIcon,
  AdditionMethod,
} from "components/common/popups/AddPeople/AddPeople";
import {
  AllDocumentsProps,
  SomeDocumentsProps,
} from "components/common/popups/CancelDocuments/CancelDocuments";
import { PopupVariant } from "components/common/popups/PopupWrapper";
import type {
  AllPeopleProps,
  SomePeopleProps,
} from "components/common/popups/RemovePeople/RemovePeople";
import SetPeopleDepartments from "components/common/popups/SetPeopleDepartments";
import {
  AccountFragment,
  BankAccountFragment,
  CheckListFragment,
  ClientFragment,
  DepartmentFragment,
  ErrorFragment,
  FreelanceProfileFragment,
  HelpArticleFragment,
  InvoiceFragment,
  JobFragment,
  PaymentMethodFragment,
  ReferencePersonFragment,
  SignatureFragment,
  WbReportFragment,
} from "graphql/fragments";
import { ErrorCodeEnum, FreelanceTypeEnum } from "graphql/types";
import { TTextColor } from "tw-generated";

import { useDialog } from "./useDialog";

type ErrorHandler = (errorMessage: string) => Promise<unknown>;

interface PopupBase {
  id: string;
  variant?: PopupVariant;
  isClosing?: boolean;
  isChanging?: boolean;
}

interface AccountAddressPopup extends PopupBase {
  id: "AccountAddress";
}
interface AccountEmailPopup extends PopupBase {
  id: "AccountEmail";
}
interface AccountNamePopup extends PopupBase {
  id: "AccountName";
  props: { name: string };
}
interface AccountPhonePopup extends PopupBase {
  id: "AccountPhone";
  props: { phone: string };
}

interface ArchivePeoplePopup extends PopupBase {
  id: "ArchivePeople";
  props: { personIds: string[] };
}
interface UnarchivePeoplePopup extends PopupBase {
  id: "UnarchivePeople";
  props: { personIds: string[] };
}

interface RefuseCheckPopup extends PopupBase {
  id: "RefuseCheck";
  props: {
    id: string;
    type: "check" | "checkList";
    sender: string;
  };
}

interface RedirectWithMessagePopup extends PopupBase {
  id: "RedirectWithMessage";
  props: {
    title: string;
    subTitle?: string;
    linkProps?: {
      id: ActionID;
      to: string;
      label: string;
    };
    isClosable?: boolean;
  };
}

interface JobDescriptionPopup extends PopupBase {
  id: "JobDescription";
  props: { checkList: Pick<CheckListFragment, "id" | "jobDescription"> };
}

interface OnboardingVideoPopup extends PopupBase {
  id: "OnboardingVideo";
}
interface OverrideCRCPopup extends PopupBase {
  id: "OverrideCRC";
  props: { checkList: CheckListFragment };
}
interface CheckmorePricesPopup extends PopupBase {
  id: "CheckmorePrices";
}

interface DraftInvoiceInfoPopup extends PopupBase {
  id: "DraftInvoiceInfo";
  props: { invoice: InvoiceFragment };
}
interface PaymentInfoPopup extends PopupBase {
  id: "PaymentInfo";
  props: { invoice: InvoiceFragment };
}

interface BlockedManualInvoicingPopup extends PopupBase {
  id: "BlockedManualInvoicing";
  props: {
    helpArticle: HelpArticleFragment;
    clientId: string;
    freelanceProfileId: string;
  };
}
interface CancelDocumentsPopup extends PopupBase {
  id: "CancelDocuments";
  props: SomeDocumentsProps | AllDocumentsProps;
}
interface CheckLogPopup extends PopupBase {
  id: "CheckLog";
  props: {
    checkList: CheckListFragment;
  };
}
interface CheckResultPopup extends PopupBase {
  id: "CheckResult";
  props: {
    checkList: CheckListFragment;
    viewer: "sender" | "receiver";
    overrideCRC?: () => void;
  };
}
interface ConfirmActionPopup extends PopupBase {
  id: "ConfirmAction";
  props: {
    onConfirmation: () => void;
    confirmationLabel: string;
    confirmationHeading: string;
    confirmationBody: string | JSX.Element;
    isDestructive?: boolean;
    closeOnConfirmation?: "none" | "one" | "all";
  };
}
interface ClientCreationPopup extends PopupBase {
  id: "ClientCreation";
  props: {
    clientOrFreelanceProfileId: ClientFragment | string;
    onSuccess?: (client: ClientFragment) => void;
  };
}
interface FreelanceProfilePopup extends PopupBase {
  id: "FreelanceProfile";
  props: {
    freelanceType: FreelanceTypeEnum;
    profile?: FreelanceProfileFragment;
    onSuccess?: (profile: FreelanceProfileFragment) => void;
  };
}
interface ReferencePersonPopup extends PopupBase {
  id: "ReferencePerson";
  props: {
    parent: ClientFragment | FreelanceProfileFragment;
    referencePerson: ReferencePersonFragment | null;
    onSuccess: (person: ReferencePersonFragment) => void;
  };
}
interface JobCreationPopup extends PopupBase {
  id: "JobCreation";
  props?: { job: JobFragment };
}
interface SignaturePopup extends PopupBase {
  id: "Signature";
  props: {
    signature: SignatureFragment;
    onSignature: (signature: {
      data: string;
      firstName: string;
      lastName: string;
    }) => void;
  };
}

interface CancelSubscriptionPopup extends PopupBase {
  id: "CancelSubscription";
  props: { onSuccess?: () => void };
}

interface UpdateSubscriptionPopup extends PopupBase {
  id: "UpdateSubscription";
  props: { onSuccess?: () => void };
}

interface AddBulkRecipientsPopup extends PopupBase {
  id: "AddBulkRecipients";
  props: {
    documentID: string;
    title: string;
    onSuccess?: () => void;
    availableMethods?: AdditionMethod[];
    description?: string;
    icon?: AddPeopleIcon;
  };
}
interface AddPeoplePopup extends PopupBase {
  id: "AddPeople";
  props: {
    checkRequestID: string;
    title: string;
    onSuccess?: () => void;
    availableMethods?: AdditionMethod[];
    description?: string;
    icon?: AddPeopleIcon;
    isCRC?: boolean;
  };
}

interface StepLoaderPopup extends PopupBase {
  id: "StepLoader";
  props: {
    title: string;
    steps: string[];
    action: (
      onSuccess: () => void,
      onError: (errors: ErrorFragment[]) => void
    ) => void;
    isAutoComplete?: boolean;
    onComplete?: () => void;
  };
}

interface RemovePeoplePopup extends PopupBase {
  id: "RemovePeople";
  props: SomePeopleProps | AllPeopleProps;
}
interface SetPeopleDepartmentsPopup extends PopupBase {
  id: "SetPeopleDepartments";
  props: SomePeopleProps | AllPeopleProps;
}

interface AddBankAccountPopup extends PopupBase {
  id: "AddBankAccount";
  props?: { freelanceProfileId?: string; onSuccess?: () => void };
}

interface ViewBankAccountPopup extends PopupBase {
  id: "ViewBankAccount";
  props: { bankAccount: BankAccountFragment };
}

interface AddTeamPopup extends PopupBase {
  id: "AddTeam";
  props: { onSuccess?: (account: AccountFragment) => void };
}
interface DeleteAccountPopup extends PopupBase {
  id: "DeleteAccount";
}
interface TeamMemberPopup extends PopupBase {
  id: "TeamMember";
  props?: { account?: AccountFragment; onSuccess?: () => void };
}
interface TeamInfoPopup extends PopupBase {
  id: "TeamInfo";
  props: {
    account: AccountFragment;
    onSuccess?: (account: AccountFragment) => void;
  };
}

interface DepartmentPopup extends PopupBase {
  id: "Department";
  props?: {
    department?: DepartmentFragment;
    onSuccess?: (department: DepartmentFragment) => void;
  };
}

interface DocumentDrawerPopup extends PopupBase {
  id: "DocumentDrawer";
  props: { documentId: string };
}

interface RegistrationSuccessPopup extends PopupBase {
  id: "RegistrationSuccess";
}

interface WbReportPopup extends PopupBase {
  id: "WbReport";
  props: { wbReportId: string };
}
interface UpdateWbReportPopup extends PopupBase {
  id: "UpdateWbReport";
  props: { wbReport: WbReportFragment };
}
interface AssignWbHandlersPopup extends PopupBase {
  id: "AssignWbHandlers";
  props: { wbReport: WbReportFragment };
}
interface RoutineGuidelinesPopup extends PopupBase {
  id: "RoutineGuidelines";
}

interface AddPaymentMethodPopup extends PopupBase {
  id: "AddPaymentMethod";
  props: {
    hasPaymentMethods: boolean;
    onSuccess?: () => void;
  };
}
interface EditPaymentMethodPopup extends PopupBase {
  id: "EditPaymentMethod";
  props: { paymentMethod: PaymentMethodFragment };
}

interface LibraryPopup extends PopupBase {
  id: "LibraryPopup";
  props: {
    title: string;
    popupSize?: "sm" | "md" | "lg";
    subTitle?: string;
    leadingIcon?: IconType;
    leadingIconColor?: TTextColor;
  };
}

export type Popup =
  | AccountAddressPopup
  | AccountEmailPopup
  | AccountNamePopup
  | AccountPhonePopup
  | AddBulkRecipientsPopup
  | ArchivePeoplePopup
  | UnarchivePeoplePopup
  | RefuseCheckPopup
  | RedirectWithMessagePopup
  | JobDescriptionPopup
  | CheckmorePricesPopup
  | DraftInvoiceInfoPopup
  | OnboardingVideoPopup
  | OverrideCRCPopup
  | PaymentInfoPopup
  | BlockedManualInvoicingPopup
  | CancelDocumentsPopup
  | CancelSubscriptionPopup
  | UpdateSubscriptionPopup
  | CheckLogPopup
  | CheckResultPopup
  | ConfirmActionPopup
  | ClientCreationPopup
  | FreelanceProfilePopup
  | ReferencePersonPopup
  | JobCreationPopup
  | SignaturePopup
  | AddPeoplePopup
  | RemovePeoplePopup
  | SetPeopleDepartmentsPopup
  | AddBankAccountPopup
  | ViewBankAccountPopup
  | AddTeamPopup
  | DeleteAccountPopup
  | TeamMemberPopup
  | TeamInfoPopup
  | DepartmentPopup
  | DocumentDrawerPopup
  | StepLoaderPopup
  | RegistrationSuccessPopup
  | WbReportPopup
  | UpdateWbReportPopup
  | AssignWbHandlersPopup
  | RoutineGuidelinesPopup
  | AddPaymentMethodPopup
  | EditPaymentMethodPopup
  | LibraryPopup;

interface Context {
  handleSecondSecurityLevelRequiredError: ErrorHandler;
  openPopup: (popup: Popup, replaceExisting?: boolean) => void;
  closeAllPopups: () => void;
  closeOnePopup: () => void;
  isClosing: boolean;
  isChanging: boolean;
}

export const PopupContext = React.createContext<Context>({
  handleSecondSecurityLevelRequiredError: () => new Promise(() => null),
  openPopup: () => null,
  closeAllPopups: () => null,
  closeOnePopup: () => null,
  isClosing: false,
  isChanging: false,
});

interface Props {
  children?: React.ReactNode;
}

export const PopupProvider = ({ children }: Props): JSX.Element => {
  const [isClosing, setIsClosing] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [activePopups, setActivePopups] = useState<Popup[]>([]);

  const shouldReduceMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  ).matches;

  const animationDuration = 400;

  const closeAllPopups = () => {
    const hasSlideOver = activePopups.some(
      (popup) => popup.variant === "slideOver"
    );
    const onClose = () => setActivePopups([]);
    if (!hasSlideOver || shouldReduceMotion) return onClose();

    setIsClosing(true);
    setTimeout(onClose, animationDuration);
  };

  const closeOnePopup = () => {
    const popupToClose = activePopups[activePopups.length - 1];
    const isSlideOver = popupToClose.variant === "slideOver";
    const onClose = () => setActivePopups(activePopups.slice(0, -1));
    if (!isSlideOver || shouldReduceMotion) return onClose();

    const hasPreviousPopup = activePopups.length > 1;
    if (hasPreviousPopup) {
      setIsChanging(true);
      setActivePopups([...activePopups.slice(0, -1)]);
      return setTimeout(() => setIsChanging(false), animationDuration);
    }

    setIsClosing(true);
    setTimeout(onClose, animationDuration);
  };

  const {
    reject: rejectPhoneDialog,
    resolve: resolvePhoneDialog,
    isOpen: isPhoneDialogOpened,
    handleError: handleSecondSecurityLevelRequiredError,
  } = useDialog((code: string) => code === ErrorCodeEnum.AuthLevel_2Required);

  // Close popups on browser navigation
  useEffect(() => {
    window.addEventListener("popstate", closeAllPopups);

    return () => window.removeEventListener("popstate", closeAllPopups);
  }, []);

  // Set is closing after popup has been closed
  useEffect(() => {
    setIsClosing(false);
  }, [activePopups.length]);

  return (
    <PopupContext.Provider
      value={{
        handleSecondSecurityLevelRequiredError,
        openPopup: (popup, replaceExisting) => {
          const variant = ((popupId) => {
            switch (popupId) {
              case "CheckResult":
              case "CheckLog":
              case "SetPeopleDepartments":
              case "TeamMember":
              case "DocumentDrawer":
              case "WbReport":
              case "AddPaymentMethod":
              case "EditPaymentMethod":
              case "RoutineGuidelines":
                return "slideOver";

              default:
                return "popup";
            }
          })(popup.id);

          if (
            (popup.variant ?? variant) === "slideOver" &&
            activePopups[0]?.variant === "slideOver"
          ) {
            setIsChanging(true);
            setTimeout(() => setIsChanging(false), animationDuration);
          }

          setActivePopups(
            replaceExisting
              ? [...activePopups.slice(0, -1), { variant, ...popup }]
              : [...activePopups, { variant, ...popup }]
          );
        },
        closeAllPopups,
        closeOnePopup,
        isClosing,
        isChanging,
      }}
    >
      {children}

      <div>
        {activePopups.map((popup, index) => {
          const key = `${popup.id}:${index}`;
          const props = {
            variant: popup.variant ?? "popup",
            hasPrevious: index > 0,
          };

          switch (popup?.id) {
            case "AccountAddress":
              return <AccountAddress key={key} {...props} />;
            case "AccountEmail":
              return <AccountEmail key={key} {...props} />;
            case "AccountName":
              return <AccountName key={key} {...props} {...popup.props} />;
            case "AccountPhone":
              return <AccountPhone key={key} {...props} {...popup.props} />;

            case "ArchivePeople":
              return <ArchivePeople key={key} {...props} {...popup.props} />;
            case "UnarchivePeople":
              return <UnarchivePeople key={key} {...props} {...popup.props} />;

            case "RefuseCheck":
              return <RefuseCheck key={key} {...props} {...popup.props} />;

            // prettier-ignore
            case "RedirectWithMessage":
              return <RedirectWithMessage key={key} {...props} {...popup.props} />;

            case "JobDescription":
              return <JobDescription key={key} {...props} {...popup.props} />;

            case "OnboardingVideo":
              return <OnboardingVideo key={key} {...props} />;

            case "OverrideCRC":
              return <OverrideCRC key={key} {...props} {...popup.props} />;

            case "PaymentInfo":
              return <PaymentInfo key={key} {...props} {...popup.props} />;

            case "CheckmorePrices":
              return <CheckmorePrices key={key} {...props} />;

            case "DraftInvoiceInfo":
              return <DraftInvoiceInfo key={key} {...props} {...popup.props} />;

            // prettier-ignore
            case "BlockedManualInvoicing":
              return <BlockedManualInvoicing key={key} {...props} {...popup.props} />;

            case "CancelDocuments":
              return <CancelDocuments key={key} {...props} {...popup.props} />;

            case "CheckLog":
              return <CheckLog key={key} {...props} {...popup.props} />;

            case "CheckResult":
              return <CheckResult key={key} {...props} {...popup.props} />;

            case "ConfirmAction":
              return <ConfirmAction key={key} {...props} {...popup.props} />;

            case "ClientCreation":
              return <ClientCreation key={key} {...props} {...popup.props} />;

            case "DocumentDrawer":
              return <DocumentDrawer key={key} {...props} {...popup.props} />;

            case "FreelanceProfile":
              return <FreelanceProfile key={key} {...props} {...popup.props} />;

            case "ReferencePerson":
              return <ReferencePerson key={key} {...props} {...popup.props} />;

            case "JobCreation":
              return <JobCreation key={key} {...props} {...popup.props} />;

            case "Signature":
              return <Signature key={key} {...props} {...popup.props} />;

            // prettier-ignore
            case "CancelSubscription":
              return <CancelSubscription key={key} {...props} {...popup.props} />;

            case "UpdateSubscription":
              return (
                <UpdateSubscription key={key} {...props} {...popup.props} />
              );

            // prettier-ignore
            case "AddBulkRecipients":
              return <AddBulkRecipients key={key} {...props} {...popup.props} />;

            case "AddPeople":
              return <AddPeople key={key} {...props} {...popup.props} />;

            case "RemovePeople":
              return <RemovePeople key={key} {...props} {...popup.props} />;

            // prettier-ignore
            case "SetPeopleDepartments":
              return <SetPeopleDepartments key={key} {...props} {...popup.props} />;

            case "AddBankAccount":
              return <AddBankAccount key={key} {...props} {...popup.props} />;

            case "ViewBankAccount":
              return <ViewBankAccount key={key} {...props} {...popup.props} />;

            case "StepLoader":
              return <StepLoaderPopup key={key} {...props} {...popup.props} />;

            case "AddTeam":
              return <AddTeam key={key} {...props} {...popup.props} />;

            case "DeleteAccount":
              return <DeleteAccount key={key} {...props} />;

            case "TeamMember":
              return <TeamMember key={key} {...props} {...popup.props} />;

            case "TeamInfo":
              return <TeamInfo key={key} {...props} {...popup.props} />;

            case "Department":
              return <Department key={key} {...props} {...popup.props} />;

            case "WbReport":
              return <WbReport key={key} {...props} {...popup.props} />;

            case "UpdateWbReport":
              return <UpdateWbReport key={key} {...props} {...popup.props} />;

            case "AssignWbHandlers":
              return <AssignWbHandlers key={key} {...props} {...popup.props} />;

            case "RoutineGuidelines":
              return <RoutineGuidelines key={key} {...props} />;

            case "AddPaymentMethod":
              return <AddPaymentMethod key={key} {...props} {...popup.props} />;

            // prettier-ignore
            case "EditPaymentMethod":
              return <EditPaymentMethod key={key} {...props} {...popup.props} />;

            case "LibraryPopup":
              return <LibraryPopup key={key} {...props} {...popup.props} />;

            default:
              return null;
          }
        })}
      </div>

      {isPhoneDialogOpened && (
        <PhoneNumberVerification
          onClose={rejectPhoneDialog}
          onSuccessfulVerification={resolvePhoneDialog}
        />
      )}
    </PopupContext.Provider>
  );
};
