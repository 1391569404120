import { useContext, useState } from "react";

import { PopupVariant, PopupWrapper } from "components/common/popups";
import { TierFragment } from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PaymentDetails from "./PaymentDetails";
import PlanSelection from "./PlanSelection";
import Progress from "./Progress";
import SuccessfulUpdate from "./SuccessfulUpdate";

interface Props {
  // Popup props
  hasPrevious: boolean;
  variant: PopupVariant;
  onSuccess?: () => void;
}

export default (props: Props): JSX.Element | null => {
  const [selectedTier, setSelectedTier] = useState<TierFragment>();

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  if (!session?.account.subscription) return null;
  const currentSubscription = session.account.subscription;

  const isSuccess = selectedTier?.id === currentSubscription.tier.id;

  return (
    <PopupWrapper
      {...props}
      popupSize="lg"
      nameSpace="login"
      title={t("popup.updateSubscription.title", "Upgrade your plan")}
    >
      <Progress currentStep={isSuccess ? 3 : selectedTier ? 2 : 1} />

      {isSuccess ? (
        <div className={tw("mt-8")}>
          <SuccessfulUpdate email={session.account.email} />
        </div>
      ) : selectedTier ? (
        <div className={tw("mt-8")}>
          <PaymentDetails account={session.account} tier={selectedTier} />
        </div>
      ) : (
        // Plans have mt-10 on desktop
        <div className={tw("mt-8", "md:mt-0")}>
          <PlanSelection
            subscription={currentSubscription}
            onSelect={setSelectedTier}
          />
        </div>
      )}
    </PopupWrapper>
  );
};
